<template>
  <div>
    <base-header class="pb-6 bg-transparent">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
      <!-- Card stats -->
      <h2 class="mb-4">Powiadomienia</h2>
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <a href="">
            <stats-card class="bg-gradient-danger">
              <!-- Card body -->
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0 text-white">UWAGA!</h5>
                  <span class="h5 font-weight-bold mb-0 text-white">Niedopełnione formalności</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-white text-danger rounded-circle shadow">
                    <i class="ni ni-active-40"></i>
                  </div>
                </div>
              </div>
              <template slot="footer">
                <span class="text-nowrap text-white">
                  Prześlij zamówienie do Symfonii
                </span>
              </template>
            </stats-card>
          </a>
        </div>
        <div class="col-xl-3 col-md-6">
          <a href="#stock-alert">
            <stats-card title="Niskie stany magazynowe"
                        type="gradient-orange"
                        sub-title="4"
                        icon="ni ni-chart-pie-35"
            ></stats-card>
          </a>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card title="Problemy z zamówieniami"
                      type="gradient-red"
                      sub-title="22"
                      icon="ni ni-money-coins">
          </stats-card>

        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card title="Nowe zamówienia"
                      type="gradient-green"
                      sub-title="36"
                      icon="ni ni-cart"
          >
          </stats-card>
        </div>
      </div>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-8">
          <card type="default" header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-light text-uppercase ls-1 mb-1">Podsumowanie</h6>
                <h5 class="h3 text-white mb-0">Liczba zamówień</h5>
              </div>
            </div>
            <line-chart
              :height="350"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>

          </card>
        </div>

        <div class="col-xl-4">
          <card header-classes="bg-transparent" id="stock-alert">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-uppercase text-muted ls-1 mb-1">Na stanie mniej niż 50 sztuk</h6>
                <h5 class="h3 mb-0">Niskie stany magazynowe</h5>
              </div>
            </div>

            <minimal-stock></minimal-stock>
          </card>
        </div>
      </div>
      <!-- End charts-->

      <!--Lists-->
      <div class="row">
        <div class="col-xl-7">
          <order-problems></order-problems>
        </div>

        <div class="col-xl-5">
          <questions-feed></questions-feed>
        </div>
      </div>
      <!--End lists-->
    </div>

  </div>
</template>

<script>
// Charts
import * as chartConfigs from '@/components/Charts/config';
import LineChart from '@/components/Charts/LineChart';

// Components
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import StatsCard from '@/components/Cards/StatsCard';
import OrderProblems from '@/views/Dashboard/OrderProblems';

// Lists
import QuestionsFeed from '@/views/Dashboard/QuestionsFeed';
import MinimalStock from '@/views/Dashboard/MinimalStock';

export default {
  components: {
    QuestionsFeed,
    LineChart,
    RouteBreadCrumb,
    StatsCard,
    MinimalStock,
    OrderProblems
  },
  data() {
    return {
      bigLineChart: {
        chartData: {
          datasets: [
            {
              // label: 'Zamówienia',
              data: [10, 20, 10, 50, 45, 40, 20, 60, 20, 30, 80, 100],
            },
          ],
          labels: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
        },
        extraOptions: chartConfigs.blueChartOptions,
      }
    };
  },
};
</script>
