<template>
  <ul class="list-group list-group-flush list my--3">
    <li class="list-group-item px-0"
        v-for="user in users"
        :key="user.id"
    >
      <div class="row align-items-center">
        <div class="col-auto">
          <!-- Avatar -->
          <a href="#" class="avatar">
            <img alt="Image placeholder" :src="user.img">
          </a>
        </div>
        <div class="col ml--2">
          <h4 class="mb-0">
            <a href="#!">{{user.name}}</a>
          </h4>
        </div>
        <div class="col-auto">
          <div class="qty-info" v-if="user.qtyType !== 'error' ">
            <span :class="`text-${user.qtyType}`">● </span>
            <small>{{user.status}}</small>
          </div>
          <div class="qty-info bg-danger d-inline-block py-1 px-2 rounded mt-1 text-white" v-else>
            <span>● </span>
            <small>{{user.status}}</small>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>

export default {
  name: 'minimal-stock',
  data() {
    return {
      users: [
        {
          id: 1,
          img: 'img/theme/clochee-cms.jpg',
          name: 'Antyoksydacyjne serum olejowe z witaminą C',
          status: '49 szt.',
          qtyType: 'success'
        },
        {
          id: 2,
          img: 'img/theme/clochee-cms.jpg',
          name: 'Antyoksydacyjne serum olejowe z witaminą C',
          status: '24 szt.',
          qtyType: 'warning'
        },
        {
          id: 3,
          img: 'img/theme/clochee-cms.jpg',
          name: 'Antyoksydacyjne serum olejowe z witaminą C',
          status: '12 szt.',
          qtyType: 'danger'
        },
        {
          id: 3,
          img: 'img/theme/clochee-cms.jpg',
          name: 'Antyoksydacyjne serum olejowe z witaminą C',
          status: '3 szt.',
          qtyType: 'error'
        },
      ]
    };
  }
};
</script>
<style></style>
