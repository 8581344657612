var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_vm._m(0),_vm._l((_vm.questions),function(ref,index){
var author = ref.author;
var date = ref.date;
var question = ref.question;
var ref_answer = ref.answer;
var user = ref_answer.user;
var content = ref_answer.content;
var userId = ref_answer.userId;
return _c('question',{key:("question-number-" + index),attrs:{"question":question,"author":author,"date":date,"user":user,"content":content,"question-id":index,"user-id":userId},on:{"response":_vm.updateQuestionsList}})})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header border-bottom-0"},[_c('h5',{staticClass:"h3 mb-0"},[_vm._v("Formularz kontaktowy")])])}]

export { render, staticRenderFns }