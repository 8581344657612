<template>
  <div class="card">
    <div class="border-0 card-header">
      <h3 class="mb-0">Problemy z zamówieniami</h3>
    </div>

    <el-table class="table-responsive table-flush"
              header-row-class-name="thead-light"
              :data="orders">
      <el-table-column
          label="Nr zamówienia"
          prop="id"
          min-width="80px"
       ></el-table-column>
      <el-table-column
        label="Email"
        min-width="220px"
        prop="email"
      ></el-table-column>
      <el-table-column label="Status"
                       min-width="170px"
                       prop="status"
                       sortable>
        <template v-slot="{row}">
          <badge class="badge-dot mr-4" type="">
            <i :class="`bg-${row.statusType}`"></i>
            <span class="status">{{row.status}}</span>
          </badge>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  import { Table, TableColumn } from 'element-ui';

  export default {
    name: 'order-problems',
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {
        orders: [
          {
            id: '#32205',
            email: 'dawid.stolarski92@gmail.com',
            status: 'nieudana płatność',
            statusType: 'danger',
          },
          {
            id: '#32206',
            email: 'dawid.stolarski92@gmail.com',
            status: 'nieudana płatność',
            statusType: 'danger',
          },
          {
            id: '#32207',
            email: 'dawid.stolarski92@gmail.com',
            status: 'nieudana wysyłka',
            statusType: 'warning',
          },
        ],
        currentPage: 1
      };
    }
  }
</script>
