<template>
  <div class="question">
    <hr class="mt-0">
    <div class="card-header d-flex align-items-center">
      <div class="d-flex align-items-center">
        <div class="mx-3">
          <a href="#" class="text-dark font-weight-600 text-sm">
            {{ author }}
          </a>
          <small class="d-block text-muted">
            {{ date }}
          </small>
        </div>
      </div>
    </div>

    <div class="card-body">
      <p class="mb-4">
        {{ question }}
      </p>
      <!-- Comments -->
      <div v-if="user && content">
        <h5 >Wysłane odpowiedzi</h5>
        <comment
          :user-image="`img/theme/team-${userId}.jpg`"
          :user-name="user"
          :text="content"
        />
      </div>
      <div class="mb-1">
        <div v-if="!(user && content)">
          <hr>
          <h5 class="mb-4">Wyślij odpowiedź</h5>
          <div class="media align-items-start">
            <img
              alt="Image placeholder"
              class="avatar avatar-lg rounded-circle mr-4"
              src="img/theme/team-3.jpg"
            >
            <div class="media-body">
              <form>
              <textarea
                class="form-control mb-4"
                placeholder="Write your comment"
                rows="4"
                v-model="answerContent"
              ></textarea>
                <div class="text-right">
                  <base-button @click="submitAnswer">Wyślij</base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import Comment from '@/components/Feed/Comment';

  export default {
    name: 'question',
    components: {
      Comment
    },
    props: {
      questionId: String,
      author: String,
      question: String,
      date: String,
      user: String,
      userId: {
        type: Number,
        default: () => 1
      },
      content: String
    },
    data() {
      return {
        answerContent: ''
      }
    },
    methods: {
      submitAnswer() {
        this.$emit('response', {
          user: 'dawidstolarski',
          userId: 3,
          content: this.answerContent,
          id: this.questionId
        })
      }
    }
  }
</script>
