<template>
  <div class="card">
    <div class="card-header border-bottom-0">
      <h5 class="h3 mb-0">Formularz kontaktowy</h5>
    </div>
    <question
      v-for="({author, date, question, answer: { user, content, userId }}, index) in questions"
      :question="question"
      :author="author"
      :date="date"
      :user="user"
      :content="content"
      :question-id="index"
      :user-id="userId"
      :key="`question-number-${index}`"
      @response="updateQuestionsList"
    ></question>
  </div>
</template>
<script>
  import Question from "../../components/Questions/Question";

  export default {
    name: 'activity-feed',
    components: {
      Question
    },
    data() {
      return {
        questions: [
          {
            author: 'Jan Kowalski',
            date: '3 days ago',
            question: 'Personal profiles are the perfect way for you to grab their attention and persuade recruiters to continue reading your CV because you’re telling them from the off exactly why they should hire you',
            answer: {
              user: 'Dawid Stolarski',
              userId: 1,
              content: 'Cras sit amet nibh libero nulla vel metus scelerisque ante sollicitudin. Cras purus odio vestibulum in vulputate viverra turpis'
            }
          },
          {
            author: 'Dawid Stolarski',
            date: '3 days ago',
            question: 'Personal profiles are the perfect way for you to grab their attention and persuade recruiters to continue reading your CV because you’re telling them from the off exactly why they should hire you',
            answer: {
              user: '',
              userId: null,
              content: ''
            }
          }
        ]
      }
    },
    methods: {
      updateQuestionsList({user, content, userId, id}) {
        const answer = this.questions[id].answer

        answer.user = user
        answer.content = content
        answer.userId = userId
      }
    }
  }
</script>
